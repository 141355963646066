var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outer-box vertical-center-transform green-border-box" },
    [
      _vm._m(0),
      _vm.unsubscribed
        ? _c("div", [
            _vm._m(1),
            _c("br"),
            _c("br"),
            _c("h4", [_vm._v("This email has been unsubscribed.")])
          ])
        : _c("div", [
            _vm._m(2),
            _c("br"),
            _c("br"),
            _vm._m(3),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("div", { staticClass: "col-sm-6 col-sm-offset-3" }, [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": "unsubscribe-email-form" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.sendUnsubscribeEmailRequest($event)
                    }
                  }
                },
                [
                  _c(
                    "label",
                    { staticStyle: { "text-align": "left", color: "black" } },
                    [
                      _vm._v(
                        "We're always looking to improve, please share your reason for leaving us:"
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reason,
                          expression: "reason"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "input",
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "unsubscribe-email-form.reason"
                        )
                      },
                      attrs: { name: "reason" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.reason = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Select here")
                      ]),
                      _c(
                        "option",
                        {
                          attrs: { value: "Your emails are not relevant to me" }
                        },
                        [_vm._v("Your emails are not relevant to me")]
                      ),
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Your emails are too frequent")
                      ]),
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "I don't remember signing up for this"
                          }
                        },
                        [_vm._v("I don't remember signing up for this")]
                      ),
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "I no longer want to receive these emails"
                          }
                        },
                        [_vm._v("I no longer want to receive these emails")]
                      )
                    ]
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "label",
                    { staticStyle: { "text-align": "left", color: "black" } },
                    [
                      _vm._v(
                        "Confirm your email address you want to unsubscribe:"
                      )
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'"
                      }
                    ],
                    staticClass: "input",
                    class: {
                      input: true,
                      "is-danger": _vm.errors.has(
                        "unsubscribe-email-form.email"
                      )
                    },
                    attrs: {
                      type: "text",
                      name: "email",
                      placeholder: "Enter your email"
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  }),
                  _c("br"),
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-6",
                      staticStyle: { "margin-bottom": "20px" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn-positive",
                          staticStyle: {
                            "margin-bottom": "10px",
                            "background-color": "#12db6d",
                            color: "white",
                            "font-weight": "500",
                            "text-decoration": "none"
                          },
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.checkDeviceTypeAndRedirect }
                        },
                        [_vm._v("CHECK MY PICKL CASH BALANCE")]
                      ),
                      _vm._v("  \n\n            ")
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-negative",
                        staticStyle: {
                          "margin-top": "0px !important",
                          "font-size": "15px",
                          "font-weight": "100"
                        },
                        attrs: { type: "submit", disabled: _vm.loading }
                      },
                      [_vm._v("UNSUBSCRIBE NOW")]
                    ),
                    _c("br"),
                    _c("br")
                  ])
                ]
              ),
              _vm.deviceCheckCompleted && _vm.deviceType == "unknown"
                ? _c("div", { staticClass: "row" }, [
                    _c("h4", { staticStyle: { padding: "5%" } }, [
                      _vm._v(
                        "Download App for your mobile device from these links!"
                      )
                    ]),
                    _vm._m(4),
                    _vm._m(5)
                  ])
                : _vm._e()
            ])
          ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-xs-12 text-center",
        staticStyle: { "margin-top": "20px" }
      },
      [
        _c("img", {
          staticClass: "pickl-logo",
          attrs: { src: "/img/pickl-logo.png" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticStyle: { "font-size": "175%" },
        attrs: { "data-v-6cc038e8": "" }
      },
      [
        _vm._v("Thanks again for trying "),
        _c(
          "span",
          { staticClass: "green-text", attrs: { "data-v-6cc038e8": "" } },
          [_vm._v("PICKL!")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticStyle: { "font-size": "175%" },
        attrs: { "data-v-6cc038e8": "" }
      },
      [
        _vm._v("Thank you for trying "),
        _c(
          "span",
          { staticClass: "green-text", attrs: { "data-v-6cc038e8": "" } },
          [_vm._v("PICKL!")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticStyle: { color: "black", "font-weight": "500" } },
      [
        _vm._v("Before you go:"),
        _c("br"),
        _vm._v(
          " Are you sure there is no money in your PICKL account for cashout? "
        ),
        _c("br"),
        _vm._v("\n      There might also be some $5 activities near you now. ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6 col-sm-6" }, [
      _c(
        "a",
        {
          staticClass: "w-inline-block w-clearfix",
          attrs: {
            href:
              "https://apps.apple.com/app/apple-store/id1448015581?pt=119141788&ct=web&mt=8"
          }
        },
        [
          _c("img", {
            staticClass: "img-responsive",
            attrs: {
              src:
                "https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e560cff95c8f322132fe_Button%20App%20Store%20-%20400x120.png",
              loading: "lazy",
              alt: "Download the PICKL app in the Apple App Store"
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6 col-sm-6" }, [
      _c(
        "a",
        {
          staticClass: "w-inline-block",
          attrs: {
            href:
              "https://play.google.com/store/apps/details?id=com.pickl.app&hl=en_US&gl=US"
          }
        },
        [
          _c("img", {
            staticClass: "img-responsive",
            attrs: {
              src:
                "https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e44bf4f151762ad31f0e_Button%20PICKL%20on%20Google%20Play%20button.png",
              loading: "lazy",
              alt: "Download the PICKL app in the Google Play Store"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }