<template>
  <div class="outer-box vertical-center-transform green-border-box">
    <div class="col-xs-12 text-center" style="margin-top: 20px;">
      <img class="pickl-logo" src="/img/pickl-logo.png" />
    </div>
    <div v-if="unsubscribed">
      <h3 data-v-6cc038e8="" style="font-size:175%">Thanks again for trying <span data-v-6cc038e8="" class="green-text">PICKL!</span></h3><br/><br/>
      <h4>This email has been unsubscribed.</h4>
    </div>
    <div v-else>
      <h3 data-v-6cc038e8="" style="font-size:175%">Thank you for trying <span data-v-6cc038e8="" class="green-text">PICKL!</span></h3><br/><br/>
      <label style="color:black;font-weight: 500;">Before you go:<br/> Are you sure there is no money in your PICKL account for cashout? <br/>
        There might also be some $5 activities near you now. </label>
      <br/><br/><br/>
      <div class="col-sm-6 col-sm-offset-3">
          <form v-on:submit.prevent="sendUnsubscribeEmailRequest" data-vv-scope="unsubscribe-email-form">
              <label style="text-align: left;color: black;">We're always looking to improve, please share your reason for leaving us:</label><br/>
              <select class="input" v-model="reason" name="reason" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('unsubscribe-email-form.reason') }">
                  <option value="">Select here</option>
                  <option value="Your emails are not relevant to me">Your emails are not relevant to me</option>
                  <option value="">Your emails are too frequent</option>
                  <option value="I don't remember signing up for this">I don't remember signing up for this</option>
                  <option value="I no longer want to receive these emails">I no longer want to receive these emails</option>
              </select><br/><br/>
              <label style="text-align: left;color: black;">Confirm your email address you want to unsubscribe:</label>
              <input type="text" class="input" name="email" v-model="email" placeholder="Enter your email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('unsubscribe-email-form.email') }"/><br/>

              <div class="col-sm-6" style="margin-bottom: 20px;">
                <a href="javascript:;" class="btn-positive" @click="checkDeviceTypeAndRedirect" style="margin-bottom: 10px;background-color: #12db6d;color: white;font-weight: 500;text-decoration: none;">CHECK MY PICKL CASH BALANCE</a>&nbsp;&nbsp;

              </div>
              <div class="col-sm-6">
                <button class="btn-negative" type="submit" style="margin-top: 0px !important; font-size: 15px;font-weight: 100;" :disabled="loading">UNSUBSCRIBE NOW</button><br/><br/>

              </div>
          </form>
          <div class="row" v-if="deviceCheckCompleted && deviceType =='unknown' ">
            <h4 style="padding:5%;">Download App for your mobile device from these links!</h4>
            <div class="col-xs-6 col-sm-6">
              <a href="https://apps.apple.com/app/apple-store/id1448015581?pt=119141788&amp;ct=web&amp;mt=8" class="w-inline-block w-clearfix">
                <img src="https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e560cff95c8f322132fe_Button%20App%20Store%20-%20400x120.png" loading="lazy" alt="Download the PICKL app in the Apple App Store" class="img-responsive">
              </a>
            </div>
            <div class="col-xs-6 col-sm-6">
              <a href="https://play.google.com/store/apps/details?id=com.pickl.app&amp;hl=en_US&amp;gl=US" class="w-inline-block">
                <img src="https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e44bf4f151762ad31f0e_Button%20PICKL%20on%20Google%20Play%20button.png" loading="lazy" alt="Download the PICKL app in the Google Play Store" class="img-responsive">
              </a>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PicklOverview',
  data: function () {
    return {
      email: '',
      reason: '',
      unsubscribed: false,
      loading: false,
      deviceCheckCompleted: false
    }
  },
  computed: {
    deviceType () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // windows phone
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }
      // Android detection
      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    }
  },
  methods: {
    checkDeviceTypeAndRedirect () {
      if (this.deviceType === 'Android') {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.pickl.app'
      } else if (this.deviceType === 'iOS') {
        window.location.href = 'https://apps.apple.com/in/app/pickl/id1448015581'
      } else {
        this.deviceCheckCompleted = true
      }
    },
    sendUnsubscribeEmailRequest () {
      this.$validator.validateAll('unsubscribe-email-form').then((result) => {
        if (result) {
          this.loading = true
          this.$store.dispatch('addEmedToUnsubscribersList', { email: this.email, reason: this.reason }).then((response) => {
            this.unsubscribed = true
            this.loading = false
          }, () => {
            this.loading = false
          })
        } else {
          this.$notify({ type: 'error', text: 'Pleaes enter valid email and select reason of leaving us.' })
        }
      })
    }
  }
}
</script>
<style scoped>
.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.green-border-box {
  margin-bottom: 15px;
}

.border-circle {
  width: 19%;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  margin-bottom: 44px;
}

.border-circle:before {
  content: " ";
  padding-top: 100%;
  display: block;
}

hr.journey-string {
  width: 5%;
  margin: 10% 4px 0;
  vertical-align: top;
  border-width: 2px;
  border-color: #d9d9d9;
  display: inline-block;
}

.modal-content .green-border-box .plain-center-header {
  font-weight: 500;
  font-size: 22px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.circle-label {
  font-size: 16px;
  color: black;
  width: 100%;
  text-align: center;
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 22px;
  height: 44px;
  overflow: hidden;
}

.circle-label:last-child {
  padding-right: 0;
}

.completion-time {
  border-radius: 360px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 15px;
  background: #f02a38;
  background: -webkit-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: -moz-linear-gradient(left, #f02a38 0%, #ff434c 100%);
  background: linear-gradient(to right, #f02a38 0%, #ff434c 100%);
}

.rating img:last-child {
  margin-right: 0;
}

.rating strong {
  font-weight: 500;
  font-size: 18px;
  color: black;
  margin-right: 5px;
}

.top-part {
  padding: 15px;
}

.middle-part {
  padding: 0 15px 15px;
}

.top-part h4,
.top-part h5 {
  padding: 0 75px;
  margin: 0;
}

.top-part h4 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}

.top-part h5 {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 8px;
}

.product-logo {
  left: auto;
  right: 0;
}

.middle-part .address {
  font-size: 17px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

/* .middle-part .address:first-of-type {
    min-height: 48px;
  } */

.middle-part .status {
  font-size: 19px;
  font-weight: 500;
}

.bottom-part {
  padding: 10px 0;
  background: #f7f8f9;
  font-weight: 500;
  font-size: 19px;
  color: rgba(0, 0, 0, 0.8);
  vertical-align: text-top;
  line-height: 35px;
}

.bottom-part img {
  vertical-align: text-top;
  margin-right: 3px;
}

.bottom-part .pickl-date {
  border-right: 1px solid #cfd1d3;
}

.rating {
  background-color: #f7f8f9;
  /* padding: 30px 20px; */
  float: left;
  width: 100%;
  min-height: 233px;
}

.issues {
  min-height: 233px;
}

.rating img {
  width: 19px;
  height: 17px;
  margin-right: 3px;
}

.rating img:last-child {
  margin-right: 0;
}

.attr-imgs {
  padding: 20px 10px 0;
}

.photo {
  padding: 0 10px;
  margin-bottom: 40px;
}

.img-contain {
  position: relative;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 100%;
  display: block;
  content: " ";
}

.img-contain img {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-width: 100%;
    max-height: 100%; */
}

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.3); */
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
  pointer-events: none;
}

.img-contain .img-overlay .img-count {
  border-radius: 50%;
  background-color: #00e05e;
  border: 2px solid white;
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  line-height: 21px;
}

.attr-time {
  font-size: 17px;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.normal-text {
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
}

label {
  width: 100%;
  color: rgba(89, 89, 89, 0.9);
  font-size: 17px;
  margin-top: 0;
  margin-bottom: 0;
}

.rating label {
  margin-top: 30px;
}

.btn-lg-grey,
.btn-lg-green {
  width: 200px;
}

textarea {
  margin-bottom: 0;
}

.rating h5 {
  font-weight: 500;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
}

.rate-picklr {
  cursor: pointer;
  margin-bottom: 45px;
}

.rate-picklr span {
  display: inline-block;
}

.rate-picklr img {
  width: 33px;
  height: 31px;
  margin: 0 8px;
}

.showOnMobile {
  display: none;
}

.address img {
  vertical-align: text-top;
}

.img-contain .img-overlay span:last-child {
  padding: 3px;
  background: rgba(0, 0, 0, 0.8);
}

.overlay {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay .vertical-center-transform {
  color: white;
}

.first-block {
  min-height: 230px;
}

.product-details .photo {
  margin-bottom: 14px;
}

.product-details .attr-imgs {
  padding-top: 0;
}

.product-details .missing-image {
  border: 1px dashed black;
}

@media only screen and (min-width: 1096px) and (max-width: 1199px) {
  .bottom-part {
    font-size: 15px;
    line-height: 30px;
  }

  .bottom-part > div {
    padding-left: 0;
    padding-right: 0;
  }

  .bottom-part img {
    height: 17px;
    width: auto;
  }
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-circle {
    font-size: 13px;
    line-height: 17px;
  }

  .border-circle .circle-label {
    font-size: 13px;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .bottom-part {
    padding: 0 15px;
  }

  .bottom-part .pickl-date,
  .bottom-part .pickl-time {
    padding: 10px 0;
  }

  .bottom-part .pickl-date {
    border-bottom: 1px solid #cfd1d3;
    border-right: none;
  }

  .rating {
    min-height: auto;
  }

  .issues {
    min-height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .company-logo {
    left: 20%;
  }

  .product-logo {
    right: 20%;
  }

  .top-part h4 {
    padding: 0;
    padding-top: 70px;
  }
  .top-part h5 {
    padding: 0;
  }

  .showOnMobile {
    display: inline;
  }

  /* .rating {
      padding: 15px;
    } */

  .rating strong {
    font-size: 16px;
  }

  .rated img {
    width: 15px;
    height: auto;
  }

  .img-contain .img-overlay {
    font-size: 17px;
  }

  .border-circle {
    font-size: 14px;
    line-height: 17px;
    min-width: 75px;
    min-height: 75px;
    display: block;
    margin-bottom: 0;
  }

  .border-circle .circle-label {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 20px);
    width: auto;
    text-align: left;
    bottom: auto;
    height: auto;
  }

  hr.journey-string {
    width: 100%;
    border-width: 1px;
    margin: 25px 0;
  }

  .btn-lg-grey,
  .btn-lg-green {
    width: 120px;
  }
}

.review-attention strong {
  color: #cb4f40;
}
.team-comment {
  float: left;
  text-align: left;
  border: 1px solid #12db6d;
  margin: 10px;
  border-radius: 10px;
  padding: 5px;
  background: ghostwhite;
}
.your-comment {
  float: right;
  text-align: right;
  border: 1px solid #12db6d;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
  background: #e8fff2;
}
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
  left: auto;
  right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
    background-repeat: repeat-y;
    position: static;
    transform: none;
  }

  .video-out {
    padding-left: 15px;
    min-height: auto;
    position: static;
    transform: none;
  }

  .video-box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
    padding: 15px;
  }
}

@media only screen and (max-height: 660px) {
  .outer-box {
    position: static;
    transform: none;
  }
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
